import React from 'react'

import '../css/skeleton.css'
import '../css/normalize.css'
import '../css/components.css'

function Footer() {
    return (
        <div className = "Footer container">
            <p>Template from&nbsp; <a href='https://github.com/anushbhatia/devtree'>DevTree</a> </p>
        </div>
    )
}

export default Footer
